import { Container, Flex, Heading } from "@chakra-ui/react";
import { type LinksFunction } from "@remix-run/cloudflare";
import { useLoaderData } from "@remix-run/react";
import stylesheet from "../styles/_index.css";

export const links: LinksFunction = () => {
  return [{ href: stylesheet, rel: "stylesheet" }];
};

export async function loader({
  context


}: {context: RequestContext;}): Promise<string[]> {
  if (context.request.headers.get("Save-Data") === "on") return [];

  const signingKey = await crypto.subtle.importKey(
    "raw",
    // @ts-expect-error
    Uint8Array.from(atob(context.env.URL_SIGNING_KEY), (m) => m.codePointAt(0)),
    { hash: "SHA-1", name: "HMAC" },
    false,
    ["sign"]
  );

  const expiration = Math.floor(Date.now() / 1000) + 600;
  const baseURLs = [
  `https://mediaproxy.carcrushers.cc/trailer.webm?Expires=${expiration}&KeyName=portal-media-linkgen`,
  `https://mediaproxy.carcrushers.cc/trailer.mp4?Expires=${expiration}&KeyName=portal-media-linkgen`];


  const signaturePromises = [];

  for (const baseURL of baseURLs)
  signaturePromises.push(
    crypto.subtle.sign("HMAC", signingKey, new TextEncoder().encode(baseURL))
  );

  const signatures = Array.from(
    (await Promise.allSettled(signaturePromises)).values()
  );
  const urls = [];

  for (let i = 0; i < baseURLs.length; i++) {
    const sig = signatures[i];

    if (sig.status === "rejected")
    throw new Response(`Failed to create signature for ${baseURLs[i]}`, {
      status: 500
    });

    const urlSig = btoa(String.fromCharCode(...new Uint8Array(sig.value))).
    replaceAll("+", "-").
    replaceAll("/", "_").
    replaceAll("=", "");

    urls.push(`${baseURLs[i]}&Signature=${urlSig}`);
  }

  return urls;
}

export default function () {
  const sourceURLs = useLoaderData<typeof loader>();

  return (
    <>
      <video autoPlay={true} id="home-video">
        <source src={sourceURLs[0]} type="video/webm" />
        <source src={sourceURLs[1]} type="video/mp4" />
      </video>
      <Container
        maxW="container.lg"
        paddingTop="8vh"
        position="relative"
        textAlign="left"
        zIndex="1">
        
        <Flex>
          <Heading>Fueling Destruction since 2012</Heading>
        </Flex>
      </Container>
    </>);

}